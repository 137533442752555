<template>
  <el-input
    v-model="syncModel"
    :placeholder="placeholder"
    clearable
    @clear="$emit('refresh', true)"
    @keypress.enter.native="$emit('refresh', true)"
  >
    <i
      slot="suffix"
      class="el-input__icon el-icon-search"
      @click="$emit('refresh', true)"
    />
  </el-input>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'SearchTextInput',
  props: {
    placeholder: { type: String, default: '輸入名稱' },
    value: String,
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const syncModel = useVModel(props, 'value', emit)
    return { syncModel }
  },
})
</script>

<style lang="postcss" scoped>

</style>
