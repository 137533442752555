<template>
  <BaseDialog
    :show="true"
    width="566px"
    :title="title"
    btn1="確認"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <el-form-item label="匯出票券種類" prop="couponType">
          <el-select
            v-model="formData.couponType"
            style="width: 100%"
            placeholder="請選擇"
          >
            <el-option
              v-for="item in couponTypeConfig"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="匯出格式" prop="format">
          <el-select
            v-model="formData.format"
            style="width: 100%"
            placeholder="請選擇格式"
          >
            <el-option
              v-for="item in exportFormatOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { exportFormatOptions } from '@/config/export'
import { couponTypeConfig } from '@/config/coupon'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'CouponExportOptionModal',
  components: { BaseDialog },
  props: { title: String },
  emits: ['close', 'confirm'],
  setup (props, { emit }) {
    const { initFormData, formData, formRef } = useBaseForm()
    initFormData({
      couponType: 'coupon',
      format: 'excel',
    })
    const formRules = {
      couponType: [noEmptyRules()],
      format: [noEmptyRules()],
    }

    const onConfirm = () => {
      emit('confirm', formData.couponType)
    }
    return {
      onConfirm,
      formData,
      exportFormatOptions,
      formRef,
      formRules,
      couponTypeConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  width: 100% !important;
}
::v-deep .el-select {
  width: 100% !important;
}
::v-deep .el-input__inner {
  width: 100% !important
}
</style>
